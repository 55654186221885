@use "/src/config" as *;

.modalComponent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  z-index: 200;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  .modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    background-color: $modal-scrim;
    border-color: $modal-scrim;
    border-radius: inherit;
    transition: inherit;
    will-change: opacity;
    opacity: 0.46;
  }

  .modalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalContainer {
    position: relative;
    background-color: $modal-bg-color;
    border-radius: 12px;
    width: calc(100% - 40px);
    margin: 1em 0;
    z-index: 150;

    &.modalSize_default {
      max-width: 450px;
    }

    &.modalSize_lg {
      max-width: 850px;
    }

    .modalCloseIcon,
    .modalBackIcon {
      position: absolute;
      top: 1em;
      border: 1px solid rgba($primary-text, 0.2);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      color: $primary-text;
      cursor: pointer;
      transition: 0.3s;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        border-color: rgba($primary-text, 0.5);
      }
    }

    .modalCloseIcon {
      right: 1em;
    }

    .modalBackIcon {
      left: 1em;
    }

    .modalContent {
      display: block;
      color: $primary-text;
      padding: 3em 1.5em 2em;

      &.modalAlign_left {
        text-align: left;
      }

      &.modalAlign_center {
        text-align: center;
      }

      h2 {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -1%;
      }

      h3 {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -1%;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -1%;
        color: $secondary-text;
      }

      hr {
        margin: 1.5em 0;
      }

      .footerBtn {
        display: inline-flex;
        width: 100%;
        margin: 0 0 8px 0;

        span {
          text-align: center;
        }

        &:first-of-type {
          margin-top: 1em;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .fullscreenModalContainer {
    z-index: 150;
  }

  @include breakpoint(sm) {
    .modalContainer {
      .modalContent {
        padding: 3em 3em 2em;
      }
    }
  }
}
