@use "/src/config" as *;

.logo {
  max-width: 282px;
  width: 50vw;
  height: 45vw;
  max-height: 248px;
  align-self: center;
}
.logo > img {
  width: 100%;
}
