@use "/src/config" as *;

.card {
  margin: 1rem 0;
  display: flex;
}

.card.hasBorder {
  margin: calc(1rem + 5px) 0 calc(1rem + 5px) 5px;
}

.icon {
  border-radius: 50%;
  padding: 0.75rem;
  transition: transform 0.3s;
}

.card .icon:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.historyNavContainer svg {
  background: $accent;
  color: $accent;
  margin-top: 0.83em;
}

.card div:not(.historyNavContainer) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card > div:last-of-type {
  padding: 0 1em;
}

.card > div:last-of-type p {
  font-size: 0.875rem;
}

.card div > * {
  margin: 0;
}

.card .icon:hover {
  cursor: pointer;
  transform: scale(1.05);
}

@media (max-width: 500px) {
  .card {
    margin: 1rem;
  }
}
