@use "/src/config" as *;

.videoTag,
.glp {
  position: relative;
  width: 100%;
  max-height: 80vh;
  max-width: 80vw;
  min-height: 100%;
  border-radius: 12px;
  display: block;
}

.glp {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.glp > model-viewer {
  width: 100%;
  height: 100%;
}

.icon {
  position: absolute;
  z-index: 3;
  pointer-events: auto;
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 1.5rem;
  opacity: 1;
  transition: all 0.3s;
  color: white;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    background: rgba(black, 50%);
  }

  svg {
    position: relative;
    transform: translate(0px, 3px);
    z-index: 4;
  }
}

.container {
  position: relative;
}
.glbContainer {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  min-width: unset;
}
.audioPlayer {
  display: flex;
  padding: 2rem;
}
.audioPlayer > svg {
  opacity: 0.5;
  transition: all 200ms ease-in;
}
.audioPlayer > svg:hover {
  transform: scale(1.1);
  cursor: pointer;
  opacity: 1;
}
.fullscreen > .glp {
  background-color: transparent;
  height: 75vh;
  min-width: 95vw;
}

.audioContainer {
  width: 100%;
  position: relative;
  top: -27px;
}

.thumbnail {
  width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

@media screen and (max-width: 1000px) {
  .container.fullscreen {
    position: unset;
  }

  .videoTag,
  .thumbnail,
  .glp {
    max-width: 100vw;
    object-fit: cover;
  }
}
@include breakpointMax(md) {
  .icon {
    display: none;
  }
}
@include breakpointMax(sm) {
  .videoTag,
  .thumbnail,
  .glp {
    border-radius: revert;
    max-height: revert;
  }
}
