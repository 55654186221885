@use "/src/config" as *;

.inputContainer {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  width: 100%;
  text-align: left;
}

.inputContainer.outlined {
  margin-bottom: 1rem;
  border-radius: 4px;
  position: relative;
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.inputContainer.filled {
  flex-direction: column;
  margin: 1rem 0;
}
.inputContainer input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.filledContainer {
  background-color: $secondary-shades-500;
  box-sizing: border-box;
  display: flex;
  border-radius: 8px;
  width: 100%;
  padding: 0.875em;
  align-items: center;
  border: 1px solid transparent;
}
.filledContainer:focus-within {
  border: solid 1px;
}
.filledContainer > .icon {
  flex-basis: 24px;
  margin-top: 0;
  height: 0;
  position: relative;

  svg {
    position: absolute;
    transform: translateY(-50%);
  }
}
.icon:hover {
  cursor: pointer;
}
.input.filled {
  border: none;
  background-color: transparent;
  color: $field-filled-text-color;
  padding: 0 8px;
  flex: 1;
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
}

// .input.filled:disabled {
// }

.input:focus {
  outline: none;
}
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.copyIcon {
  padding: 0 1rem;
  align-self: center;
}

.copyIcon:hover {
  cursor: pointer;
}

.inputContainer fieldset {
  border-collapse: collapse;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: 0.3s;
  border-radius: 4px;
  border: 1px solid $field-border-color;
  transition-property: color, border-width;
  color: $field-filled-text-color;
  background-color: $field-background-color;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 8px;
}

.inputContainer fieldset legend {
  color: inherit;
  display: table;
  white-space: normal;
  max-width: 100%;
  line-height: 11px;
  padding: 0;
  border-color: $field-filled-text-color;
  text-align: left;
  transition: width 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.inputContainer div.field {
  position: relative;
  background: transparent;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.inputContainer div.field input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0;
  color: $field-filled-text-color;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border: none;
  background: inherit;
  font-size: 16px;
  caret-color: $field-filled-text-color;
}
.inputContainer label.outlined {
  left: 0;
  right: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}

.inputContainer.filled label {
  font-size: 0.875rem;
  color: $field-label-color;
}
.control {
  color: $field-filled-text-color;
  border-radius: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-top: 1em;
  min-width: 0;
  width: 100%;
}

.successMsg p {
  margin: auto;
  text-align: center;
}
.control label {
  height: 20px;
  line-height: 20px;
  letter-spacing: normal;
  left: 0px;
  color: $field-label-color;
  // padding-left: 5px;
  right: auto;
  position: absolute;
  transform-origin: top left;
  transition: all 0.3s;
}

.rInput {
  cursor: text;
  background: transparent;
  padding: 0 12px;
  align-items: center;
  color: inherit;
  box-sizing: inherit;
  height: 2.8em;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

label.inputActive.outlined {
  /*

    this was for animation of label but now we keep it off now
    need to activate css in Template on fieldset
    transform: translateY(-1.525em)
                scale(.80);*/
  display: none;
}
.inputActive {
  border-color: $field-filled-text-color;
  /*
    transition: border 500ms ease-out;
    */
}

.error:not(.inputActive) {
  border-color: $field-error-text-color;
}

.errorDetails {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  min-height: 14px;
  margin: 0.5rem 0;
  overflow: hidden;
  align-items: center;
  color: field-error-text-color;
}

.errorText {
  color: $field-error-text-color !important;
  caret-color: $field-error-text-color;
  text-align: left;
}

.message {
  line-height: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@media (max-width: 767px) {
  .inputContainer.filled {
    margin: 0.5rem 0;
  }
  .input.filled {
    font-size: 0.85rem;
  }
}
