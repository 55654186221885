@use "/src/config" as *;

a.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.05rem;
  transition: all 0.3s;
  border: 1px solid;
  border-radius: 8px;
  text-transform: capitalize;
  text-decoration: none;

  span {
    display: flex;
    justify-content: center;
    padding: 0.675rem 0.4rem;
    font-size: 1.1rem;
    font-weight: bold;
  }

  &.primary {
    background-color: $primary-btn-bg;
    border-color: $primary-btn-bg;
    color: $primary-btn-text;

    span {
      color: $primary-btn-text;
    }

    &:hover:not(:disabled) {
      filter: brightness(115%);
    }
  }

  &.secondary {
    background-color: $secondary-btn-bg;
    color: $secondary-btn-text;
    border: 1px solid $secondary-btn-text;
  }

  &.transparent {
    background-color: transparent;
    color: $primary-text;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &.secondary:hover:not(:disabled) {
    background-color: $secondary-btn-hover-bg;
    color: $secondary-btn-hover-text;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.iconOnly {
    border-radius: 50%;
    padding: 1.15rem;
  }

  &.iconOnly.primary {
    background-color: $primary-btn-bg;
    color: $primary-btn-bg;
  }

  &.iconOnly.secondary {
    background-color: $secondary-btn-bg;
    color: $secondary-btn-text;
  }
}

@include breakpointMax(md) {
  a.button {
    width: 100%;
  }
}
