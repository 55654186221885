@use "/src/config" as *;

.dataTable {
  line-height: 1.5;
  max-width: 100%;
  border-radius: 8px;
  width: 100%;
}
.tableWrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
.dataTable > .tableWrapper > table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
}
.text-start {
  text-align: start !important;
}
.text-center {
  text-align: center !important;
}
.text-end {
  text-align: end !important;
}

.dataTable > .tableWrapper > table tr {
  border-top: 1px solid $tr-border-top-color;
}

.dataTable.hoverEffect {
  & > .tableWrapper > table:not(.skeleton) tr:hover {
    cursor: pointer;
    background-color: $tr-hover-bg-color;
    border-radius: 4px;
    border-top-color: $tr-border-top-color-hover;
  }
  & > .tableWrapper > table:not(.skeleton) tr:hover + tr {
    border-top-color: $tr-border-top-color-hover;
  }
  & > .tableWrapper > table:not(.skeleton) tr:hover td:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }
  & > .tableWrapper > table:not(.skeleton) tr:hover td:last-of-type {
    border-radius: 0px 4px 4px 0px;
  }
  & > .tableWrapper > table:not(.skeleton) tr:hover button {
    cursor: pointer !important;
  }
  & > .tableWrapper > table:not(.skeleton) tr:hover button > span {
    background-color: $selection-hover-bg-color;
    cursor: pointer;
  }
  & > .tableWrapper > table td > span {
    opacity: 0.5;
  }
}

.dataTable > .tableWrapper > table td,
.dataTable > .tableWrapper > table th {
  text-align: left;
  padding: 15px;
}

.smartContractTableWrapper {
  margin-top: 1.5em;
  &td:first-of-type {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.dataTable > .tableWrapper > table td {
  &.mobileRow {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.dataTableMobile > .tableWrapper > tbody {
  display: flex;
  flex-direction: column;
}
.mobileTableRow {
  &:nth-child(even) {
    background: rgba($tr-hover-bg-color, 0.3);
  }
}
td.mobileRow {
  height: auto;
  min-height: 48px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }
}
.mobileRow.selectable {
  justify-content: flex-end;
}
.mobileRow.selectable > label > button > span {
  margin: 0;
}
.mobileRowHeader {
  padding-right: 16px;
  font-weight: 600;
}
.mobileRowData {
  text-align: right;
}

.noData {
  display: flex;
  padding: 4rem;
  border: 1px solid $tr-border-top-color;
  justify-content: center;
  border-top: none;
}

@media (max-width: 320px) {
  .mobileTableRow > td:first-child {
    padding-top: 8px !important;
  }
  .mobileRow {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 !important;
    padding-left: 0.5rem !important;
  }
  .mobileRow,
  .mobileRow.selectable {
    justify-content: flex-start;
  }
}

// Ellipsis for profile name
.mobileRowData {
  flex: 1;
  width: 0;

  .mobileRowDataContainer {
    position: relative;
    width: 100%;

    & > div {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
