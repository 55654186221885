@use "/src/config" as *;

.alertContainer {
  font-size: 16px;
  padding: 0.75em 16px;
  position: relative;
  border: solid 1.5px;
  border-radius: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.alertWrapper {
  align-items: center;
  border-radius: inherit;
  display: flex;
}
.alertContent {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.alertContent p {
  margin: 0;
  opacity: 1;
  font-weight: 700;
}

.alertContainer.center {
  text-align: center;
}
.alertContainer.left {
  text-align: left;
}

.alertContainer span:first-letter {
  text-transform: capitalize;
}
.icon {
  align-items: center;
  display: inline-flex;
  font-size: 24px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  vertical-align: middle;
}
.icon:after {
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1.3);
  width: 100%;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.disposable.icon {
  cursor: pointer;
  pointer-events: auto;
}
.danger {
  color: $error-text;
  border-color: $error-text;
}
.warning {
  color: $warning-text;
  border-color: $warning-text;
}
.info {
  color: $primary-text;
}

.blank {
  background-color: $primary-btn-bg;
  border-color: $primary-btn-text;
  color: $primary-btn-text;
}

.alertContainer.hide {
  display: none;
}
.alertContainer.show {
  display: block;
}
