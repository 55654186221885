@use "/src/config" as *;

.container {
    padding: 1em;
    box-sizing: border-box;
    max-height: 366px;
}

.image {
    width: 282px;
    height: 257px;
    margin-bottom: 20px;
}

.line {
    height: 36px;
    width: 100%;
    margin-top: 10px;
}

.smallLine {
    height: 20px;
    width: 100%;
    margin-top: 10px;
}

.skeleton {
    position: relative;
    background-color: $primary-50;
    border-radius: 5px;
    overflow: hidden;
}
.skeleton:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, $primary-50, transparent);
    -webkit-animation: skeleton 1s infinite;
    animation: skeleton 1s infinite;
}

@-webkit-keyframes skeleton {
    100% {
        transform: translateX(100%);
    }
}

@keyframes skeleton {
    100% {
        transform: translateX(100%);
    }
}