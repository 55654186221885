@use "/src/config" as *;

@mixin section($fullMode: false) {
  .sectionCollectibles {
    display: flex;

    @if ($fullMode) {
      background: linear-gradient(
        to bottom,
        $bg-color 1%,
        rgba($primary, 10%) 10%,
        $bg-color
      );
    }

    .infiniteScrollLoading {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 100px;
      justify-content: center;
      align-items: center;
    }

    .collectiblesList {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      margin: 0 0 3rem;
      height: auto;
      width: 100%;

      @if ($fullMode) {
        margin: 3rem 0;
      }

      .collectibleItem {
        cursor: pointer;

        img.collectibleImg {
          width: 100%;
          aspect-ratio: 3/4;
          object-fit: cover;
          border-radius: 10px;
          background-color: $secondary-shades-100;
        }

        h5 {
          color: $primary-text;
          font-weight: 700;
          font-size: 1.25rem;
          margin: 0;
        }

        p {
          color: $secondary-shades-100;
          font-size: 1rem;
          margin: 0 0 0px;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .sectionCollectibles {
      .collectiblesList {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @include breakpoint(md) {
    .sectionCollectibles {
      .collectiblesList {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @include breakpoint(lg) {
    .sectionCollectibles {
      .collectiblesList {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  @include breakpoint(xl) {
    .sectionCollectibles {
      .collectiblesList {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  @include breakpoint(xxl) {
    .sectionCollectibles {
      .collectiblesList {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  // Show all
  .sectionCollectibles[data-show-all~="false"] {
    @include breakpointMax(sm) {
      .collectiblesList {
        .collectibleItem:nth-child(n + 5) {
          display: none;
        }
      }
    }

    @include breakpointStrict(sm, md) {
      .collectiblesList {
        .collectibleItem:nth-child(n + 5) {
          display: none;
        }
      }
    }

    @include breakpointStrict(md, lg) {
      .collectiblesList {
        .collectibleItem:nth-child(n + 7) {
          display: none;
        }
      }
    }

    @include breakpointStrict(lg, xxl) {
      .collectiblesList {
        .collectibleItem:nth-child(n + 9) {
          display: none;
        }
      }
    }

    @include breakpoint(xxl) {
      .collectiblesList {
        .collectibleItem:nth-child(n + 11) {
          display: none;
        }
      }
    }
  }
}
