@use "/src/config" as *;

.container {
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0 20px;
}

.container .data {
  display: block;
  white-space: pre-line;
}

.container .data h1 {
  margin: 0;
  font-size: 32px;
  line-height: 44px;
}

.container .data p {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  margin-block-start: 1.5em;
  margin-block-end: 1.5em;
}

.container .data button {
  margin-left: auto;
  margin-right: auto;
}

.loaderContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .container .data h1 {
    font-size: 44px;
    line-height: 56px;
  }

  .container .data p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1162px) {
  .container {
    height: 100%;
  }
}
