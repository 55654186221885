@use "/src/config" as *;
button.extensionPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $secondary-shades-700;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding: 0.875rem 0.5rem;
  margin-bottom: 0.5rem;
  color: $secondary-btn-text;
  border: 1px solid transparent;
}
button.extensionPanel:hover {
  cursor: pointer;
  pointer-events: initial;
}

button.extensionPanel > div.panelExtraInfo {
  display: flex;
  align-items: center;
  & span {
    color: rgba($secondary-btn-text, 40%);
    margin-right: 0.75rem;
  }
}

.content {
  background: $secondary-shades-700;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(80px * 5);
  overflow: auto;
}
.content > .optionWrapper:last-child {
  padding-bottom: 1rem;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.optionWrapper {
  padding-top: 0.75rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.separator {
  margin: 1rem 0;
  display: block;
  flex: 1 1;
  max-width: 100%;
  width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  transition: inherit;
  overflow: visible;
  border-color: rgba(140, 143, 151, 0.16);
}
@media (max-height: 767px) {
  .content {
    max-height: calc(64px * 3);
  }
  .separator {
    margin: 0.5rem 0;
  }
}
