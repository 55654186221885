@use "/src/config" as *;

.iconBtn {
    background: inherit;
    border: inherit;
    border-radius: 50%;
}

.iconBtn:hover {
    cursor: pointer;
}

.iconBtn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}