$accent: black !default;
$primary-bg: #ffffff !default;
$primary-text: #000 !default;
$secondary-text: rgba($primary-text, 0.7) !default;
$bg-blurred-gradient-start: #c8c8c899 !default;
$bg-blurred-gradient-end: hwb(0 100% 0% / 0.4) !default;

$primary-50: rgba(188, 43, 68, 0.5) !default;

$secondary-shades-100: #e5e5e580 !default;
$secondary-shades-500: #e1e1e5 !default;
$secondary-shades-700: #f1f1f2 !default;
$secondary-shades-900: #f8f8fa !default;

$nav-height: 72px !default;
$nav-bg: $primary-bg !default;
$form-margin: 2em !default;
$error-text: #ec5962 !default;
$warning-text: #fb8c00 !default;
$success-text: #22bb33 !default;
$info-text: #5bc0de !default;
$default-alert-text: #aaaaaa !default;
$modal-scrim: #212121 !default;
$state-bg-color: #1f5a94 !default; /* the  color background of state (ex: input focuse, modal overlay ect)*/
$light-gray: #8c8f97 !default;
$bg-blurred-filter: #ffffff !default;
$active-state: #07d38a !default;
$login-img-size: cover !default;
$login-img-position: center !default;
$primaryFont: "Circular" !default;
$secondaryFont: "Circular" !default;
$scrollbar: rgba($secondary-text, 0.2) !default;

$split-section-bg-1: $accent !default;
$split-section-bg-2: linear-gradient(
  270deg,
  rgba($secondary-shades-900, 0.8) 0%,
  rgba($secondary-shades-900, 0.56) 100%
) !default;

$split-section-filter-bg: linear-gradient(
  270deg,
  rgba($secondary-shades-900, 0.8) 0%,
  rgba($secondary-shades-900, 0.56) 100%
) !default;

$pfp-trait-name-color: $primary-text !default;

$profile-border-color: $primary-text !default;
