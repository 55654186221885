@use "/src/config" as *;

// Amount of items (+1 of the actual displayed amount!)
$items-default: 5;
$items-sm: 5;
$items-md: 7;
$items-lg: 9;
$items-xl: 9;
$items-xxl: 11;

.relativeContainer {
  position: relative;
}

.sectionHeading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 24px;

  h3 {
    margin: 0 0 24px;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 1%;
    color: $primary-text;

    a {
      margin-left: 0.4em;
    }
  }

  h3.sectionHeading,
  p {
    margin: 0;
  }

  .showAllBtn {
    display: none;
    color: $primary-text;
    opacity: 1;
    border-bottom: 1px solid rgba($primary-text, 0.4);
    cursor: pointer;
    transition: 0.3s;
    user-select: none;
    white-space: nowrap;

    &:hover {
      border-color: $primary-text;
    }
  }
}

.description {
  margin: -0.5em 0 2em 0;
  opacity: 0.7;
  line-height: 28px;
}

.sectionCollectibles {
  display: flex;

  .collectiblesList {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    margin: 0 0 3rem;
    height: auto;
    width: 100%;

    .collectibleItem {
      cursor: pointer;

      .imgContainer {
        position: relative;
        width: 100%;
        aspect-ratio: 3/4;
        margin-bottom: 0.5em;

        // Set border radius for inner elements
        $imgBorderRadius: 8px;

        .imgTag {
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: $imgBorderRadius;
          background-color: rgba(
            $primary-text,
            0.1
          ); // Fallback color in case there's no image
        }

        .imgOverlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(black, 0.4);
          border-radius: $imgBorderRadius;
        }
      }

      h5 {
        color: $primary-text;
        font-weight: 700;
        font-size: 1.25rem;
        margin: 0 0 0.1em;
      }

      p {
        color: $secondary-shades-100;
        font-size: 1rem;
        margin: 0 0 0px;
      }

      &:hover {
        h5 {
          text-decoration: underline;
        }
      }
    }
  }
}

@include breakpoint(sm) {
  .sectionCollectibles {
    .collectiblesList {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@include breakpoint(md) {
  .sectionCollectibles {
    .collectiblesList {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

@include breakpoint(lg) {
  .sectionCollectibles {
    .collectiblesList {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@include breakpoint(xl) {
  .sectionCollectibles {
    .collectiblesList {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@include breakpoint(xxl) {
  .sectionCollectibles {
    .collectiblesList {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
}

// Show all collectibles
.sectionCollectibles[data-show-all~="false"] {
  @include breakpointMax(sm) {
    .collectiblesList {
      .collectibleItem:nth-child(n + #{$items-default}) {
        display: none;
      }
    }
  }

  @include breakpointStrict(sm, md) {
    .collectiblesList {
      .collectibleItem:nth-child(n + #{$items-sm}) {
        display: none;
      }
    }
  }

  @include breakpointStrict(md, lg) {
    .collectiblesList {
      .collectibleItem:nth-child(n + #{$items-md}) {
        display: none;
      }
    }
  }

  @include breakpointStrict(lg, xl) {
    .collectiblesList {
      .collectibleItem:nth-child(n + #{$items-lg}) {
        display: none;
      }
    }
  }

  @include breakpointStrict(xl, xxl) {
    .collectiblesList {
      .collectibleItem:nth-child(n + #{$items-xl}) {
        display: none;
      }
    }
  }

  @include breakpoint(xxl) {
    .collectiblesList {
      .collectibleItem:nth-child(n + #{$items-xxl}) {
        display: none;
      }
    }
  }
}

// Show all button
@include breakpointMax(sm) {
  .sectionHeading[data-total~="#{$items-default}"] {
    .showAllBtn {
      display: block;
    }
  }
}
@include breakpointStrict(sm, md) {
  .sectionHeading[data-total~="#{$items-sm}"] {
    .showAllBtn {
      display: block;
    }
  }
}
@include breakpointStrict(md, lg) {
  .sectionHeading[data-total~="#{$items-md}"] {
    .showAllBtn {
      display: block;
    }
  }
}
@include breakpointStrict(lg, xl) {
  .sectionHeading[data-total~="#{$items-lg}"] {
    .showAllBtn {
      display: block;
    }
  }
}
@include breakpointStrict(xl, xxl) {
  .sectionHeading[data-total~="#{$items-xl}"] {
    .showAllBtn {
      display: block;
    }
  }
}
@include breakpoint(xxl) {
  .sectionHeading[data-total~="#{$items-xxl}"] {
    .showAllBtn {
      display: block;
    }
  }
}

.imgLabel {
  position: absolute;
  bottom: 1.3rem;
  right: 1rem;
  background: white;
  color: #06060a;
  padding: 5px 12px;
  border-radius: 999px;
}

.unclaimedContainer:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1;
}

.unclaimedButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  background: #fff;
  padding: 3px 12px;
  border-radius: 999px;
  font-size: 12px;
  font-weight: 500;
  width: max-content;
  z-index: 1;
}
