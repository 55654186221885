@use "/src/config" as *;

.component {
  background-color: red;
}

.component > p {
  font-size: 20px;
  color: blue;
}
