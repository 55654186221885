@use "/src/config" as *;

.chip {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 1em;
  border-radius: 30px;
  width: fit-content;
  width: -moz-fit-content;
  border: 1px solid $primary-text;
  font-size: 14px;

  & > div {
    min-height: initial;
  }
}
.chip.squared {
  border-radius: 8px;
}
.removableIcon:hover {
  cursor: pointer;
}
.chip.primary {
  background-color: $primary-chip-bg;
  color: $primary-chip-text;
  border: 1px solid $primary-chip-bg;
  font-weight: 500;
}

.chip.secondary {
  background-color: $secondary-btn-bg;
  color: $primary-text;
  font-weight: 500;
  border: 1px solid $primary-text;
}

.chip.primaryButton {
  background-color: $primary-btn-bg;
  border: $primary-btn-bg;
  color: $primary-btn-text;
  font-weight: 500;

  &:hover:not(:disabled) {
    filter: brightness(115%);
  }
}

.chip.clickable {
  cursor: pointer;
}

.chip.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.chip.secondary.clickable:hover {
  background-color: $secondary-btn-hover-bg;
  color: $secondary-btn-hover-text;
}
nav:first-child {
  text-decoration: none;
}

.loadingContainer {
  @media (min-width: 380px) {
    margin-left: 8px;
  }
  .chipText {
    @media (max-width: 380px) {
      display: none;
    }
  }
}

@include breakpointMax(md) {
  .chip.squared {
    width: 100%;
  }
}
