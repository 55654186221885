@use "/src/config" as *;

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  text-align: center;

  h1 {
    font-weight: 900;
    line-height: 1;
    margin-bottom: -10px;
    margin-top: 0.75rem;
  }

  h3 {
    opacity: 0.6;
  }

  & > .logo {
    max-width: 282px;
    width: 50vw;
    height: 45vh;
    max-height: 248px;
    align-self: center;

    & > img {
      width: 100%;
    }
  }

  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    z-index: 2;
    margin: 1em 0 0;
    padding: 6px 12px;
    border: 1px solid $primary-text;
    border-radius: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1300px) {
  .logo {
    width: 128px;
    height: 104px;
  }
  .content .logo > img {
    margin: 0;
  }
  .content h1 {
    margin: 0;
  }
}
