@use "/src/config" as *;

.container {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100px;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 4px solid $primary-text;
  border-top: 4px solid $primary-bg;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
