@use "/src/config" as *;

.lockedAlert {
  background: $utility-locked-alert-bg;
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 0.875rem;
  color: $utility-locked-alert-text;
  padding: 13px 10px;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    color: $utility-locked-alert-text;
    font-weight: 700;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
  }
}

p.modalText {
  margin: 0 0 1em 0;
}
