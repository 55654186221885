@use "/src/config" as *;

.container {
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: $primary-text;
  font-weight: 500;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin-bottom: 10px;
    max-width: fit-content;
    &:nth-of-type(1) {
      flex: 1 0 100%;
    }
    &:nth-of-type(2) {
      flex: 2 1 100%;
    }
    &:nth-of-type(3) {
      flex: 3 2 100%;
    }
    &:nth-of-type(4) {
      flex: 4 3 100%;
    }
  }
}
