// @value (
// primary-btn-color,
// primary-btn-text-color,
// primary-btn-onhover-color,
// secondary-btn-color,
// secondary-btn-text-color,
// secondary-btn-onhover-color,
// secondary-btn-on-hover-text-color,
// primary-icon-btn-color,
// primary-icon-btn-bg-color,
// primary-btn-disabled-color,
// secondary-btn-disabled-color,
// secondary-icon-btn-color,
// secondary-icon-btn-bg-color
// )
// from "../../../config/styles/variables.css";

@use "/src/config" as *;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 2.5em; */
  padding: 0 1.05rem;
  transition: all 0.3s;
  border: 1px solid;
  border-radius: 8px;
  text-transform: uppercase;
}

.button span:not(.loaderCircle) {
  display: flex;
  justify-content: center;
  padding: 0.675rem 0.4rem;
  font-size: 1.1rem;
  @include breakpointMax(sm) {
    padding: 1.2rem 0.4rem;
  }
}

.button.primary {
  background-color: $primary-btn-bg;
  border-color: $primary-btn-bg;
  color: $primary-btn-text;

  &:hover:not(:disabled) {
    filter: brightness(115%);
  }
}

.button.primary span {
  color: $primary-btn-text;
}
.button.secondary {
  background-color: $secondary-btn-bg;
  color: $secondary-btn-text;
  border: 1px solid $secondary-btn-text;
}

.button.transparent {
  background-color: transparent;
  color: $primary-text;
}

.button.simplified {
  background-color: transparent;
  border: unset;
  color: $primary-text;

  &:hover {
    text-decoration: underline;
  }
}

.button:hover:not(:disabled) {
  cursor: pointer;
}

.button.secondary:hover:not(:disabled) {
  background-color: $secondary-btn-hover-bg;
  color: $secondary-btn-hover-text;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.iconOnly {
  border-radius: 50%;
  padding: 1.15rem;
}

.button.iconOnly.primary {
  background-color: $primary-btn-bg;
  color: $primary-btn-bg;
}

.button.iconOnly.secondary {
  background-color: $secondary-btn-bg;
  color: $secondary-btn-text;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  height: 2.5em;
}
.loadingContainer div {
  margin-left: 0.5em;
}

.button.square span {
  padding: 0px;
}

@media (max-width: 700px) {
  .button {
    width: 100%;
  }
}
