@use "/src/config" as *;

.card {
  position: relative;
  border-radius: 8px;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  width: 190px;
  box-sizing: border-box;
  color: #69727b;
}

.card div:first-of-type {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}

.card img {
  overflow: hidden;
  border-radius: 8px;
  max-height: 25vh;
  margin-bottom: 8px;
  object-fit: cover;
}

.card > h5,
.card > h4 {
  margin: 0;
}
.card > h4 {
  margin-bottom: 4px;
}

.loader {
  z-index: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader > div:first-of-type {
  background-color: $light-gray;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 8px;
  opacity: 0.4;
}

.loader.success > div:first-of-type {
  opacity: 0;
}
.loader .circle {
  height: 24px;
  width: 24px;
}
.loader button {
  z-index: 10;
  position: relative;
  margin: 0;
}
.loader button span {
  margin: 0 !important;
}

@include breakpointMax(sm) {
  .card {
    background: $modal-bg-color;
    width: 100%;
    align-items: center;
    div:first-of-type {
      align-items: center;
    }
    h4 {
      font-size: 1.5em;
    }
    img {
      max-height: 50%;
      max-width: 50%;
      width: 100%;
      object-fit: contain;
    }
  }
}
