@use "/src/config" as *;

.pickemFooter {
  @include container;

  background-color: #061148;
  display: flex;
  padding: 2em 0 3em;

  hr {
    margin: 1.5em 0;
  }

  .upperSection {
    display: block;
    position: relative;
    align-items: center;

    ul.upperNav {
      display: block;
      list-style: none;
      margin: 0 0 0 0;
      padding: 0;

      li {
        display: block;
        margin: 0 0 12px 0;

        a {
          text-decoration: none;
          color: $secondary-shades-100;
          font-size: 1em;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    ul.upperSocial {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      list-style: none;
      margin: 0 0 0 auto;
      padding: 0;

      li {
        display: inline;
        padding: 0 24px 0 0;

        a {
          text-decoration: none;
          color: $secondary-shades-100;
          font-size: 1.5em;
        }

        &:last-child {
          padding: 0;
        }
      }
    }
  }

  .logoSection {
    ul.logoList {
      display: grid;
      grid-template-columns: repeat(4, 48px);
      gap: 12px;
      justify-content: space-between;
      list-style: none;
      padding: 0;

      li {
        display: inline;

        img {
          width: 100%;
        }
      }
    }
  }

  .infoSection {
    margin-bottom: 20px;

    p {
      font-size: 0.75em;
      color: $secondary-shades-100;
      margin-bottom: 1em;

      &.logout {
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
      }
    }
  }

  .legalLogoSection {
    display: flex;
    align-items: center;
    gap: 40px;

    img {
      max-width: 100px;
      max-height: 144px;
    }
  }
}

@include breakpoint(sm) {
  .pickemFooter {
    .logoSection {
      ul.logoList {
        grid-template-columns: repeat(6, 48px);
      }
    }

    .legalLogoSection {
      img {
        max-width: unset;
        max-height: 144px;
      }
    }
  }
}

@include breakpoint(md) {
  .pickemFooter {
    .logoSection {
      ul.logoList {
        grid-template-columns: repeat(7, 48px);
      }
    }
  }
}

@include breakpoint(lg) {
  .pickemFooter {
    .logoSection {
      ul.logoList {
        grid-template-columns: repeat(16, 48px);
      }
    }

    .infoSection {
      max-width: 80%;
    }
  }
}

@include breakpoint(xl) {
  .pickemFooter {
    .upperSection {
      display: flex;

      ul.upperNav {
        li {
          display: inline;
          padding: 0 20px 0 0;

          &:last-child {
            padding: 0;
          }
        }
      }

      ul.upperSocial {
        position: relative;
      }
    }

    .logoSection {
      ul.logoList {
        display: grid;
        grid-template-columns: repeat(16, 48px);
        gap: 10px;
        list-style: none;
        padding: 0;

        li {
          display: inline;

          img {
            width: 100%;
          }
        }
      }
    }

    .infoSection {
      max-width: 80%;
    }
  }
}
