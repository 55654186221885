@use "/src/config" as *;

.sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  margin-top: 10px;
  &.mobileSort {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin-top: 10px;
  }
}
.sort > div:first-of-type {
  display: flex;
  align-items: center;
}
.sort span {
  max-height: 32px;
}
.sort .dropdown > div {
  margin: 0;
}
.sort .dropdown > div > div {
  height: 40px;
  width: 176px;
}
.sort > div > span:first-of-type {
  margin-right: 10px;
}
.selectModal main {
  margin: 0;
  padding: 0;
  background-color: initial;
  height: auto !important;
  box-sizing: border-box;
  width: auto;
  min-height: unset;
  min-width: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}
#editionsScroll {
  overflow: auto;
  min-height: unset;
  max-height: 50vh;
  margin: 1em 0;
}

#editionsScroll > div:first-child > div:first-child {
  overflow: hidden !important;
}

.selectModal footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include breakpointMax(sm) {
  #editionsScroll {
    margin: 1em 0;
  }
  .selectModal h1 {
    margin-top: 0;
  }
  .sort {
    flex-direction: column;
  }
  .sort > div:first-of-type {
    display: unset;
    align-items: unset;
  }
  .sort {
    &.mobileSort {
      margin-top: 24px;
    }
  }
}
