@use "/src/config" as *;

.enter {
    bottom: -100px;
    transform: scale(0.9);
}
.enterActive {
    opacity: 1;
    transform: translatey(-120px);
    transition: all 300ms;
}
.exit {
    opacity: 1;
}
.exitActive {
    opacity: 0;
    transform:
            scale(0.9)
            translateY(120px);
    transition: all 300ms;
}