@use "breakpoints" as *;

@mixin container {
  .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint(sm) {
    .container {
      max-width: 540px;
    }
  }

  @include breakpoint(md) {
    .container {
      max-width: 720px;
    }
  }

  @include breakpoint(lg) {
    .container {
      max-width: 960px;
    }
  }

  @include breakpoint(xl) {
    .container {
      max-width: 1140px;
    }
  }

  @include breakpoint(xxl) {
    .container {
      max-width: 1320px;
    }
  }
}
