@use "/src/config" as *;

.checkbox {
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  color: $primary-text;

  box-sizing: content-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 4px;

  text-align: left;

  transition: all 0.3s;
}

.checkbox:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.checkbox:hover {
  border: 2px solid #ffffff;
}

.checkbox span {
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border: solid 6px $secondary-shades-500;
  border-radius: 50%;
  transition: all 0.3s;
  color: #ffffff;
  background-color: $secondary-shades-500;
}

.checkbox:hover span {
  background-color: #ffffff;
}

.checkbox span svg {
  transition: all 0.4s;
  transform: scale(0) rotate(-180deg);
  stroke-width: 4px;
  width: 16px;
  height: 16px;
}

.checkbox .loader {
  margin-right: 10px;
}

.checkbox.selected {
  background-color: #ffffff;
  color: #ffffff;
}

.checkbox.selected span {
  border: solid 6px #ffffff;
  background-color: #ffffff;
}

.checkbox.selected span svg {
  transform: scale(1) rotate(0);
}

.checkbox.naked {
  border: none;
  background-color: transparent;
  height: auto;
  padding: 0;
  color: $primary-text !important;
  font-size: 14px;
}

.checkbox.naked span {
  color: #000;
  margin-right: 15px;
}

.checkbox.naked .loader {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  padding: 2px;
  box-sizing: border-box;
}

.checkbox.selected.naked span {
  background-color: #ffffff;
}
