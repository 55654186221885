@use "/src/config" as *;

.inputField {
  position: relative;

  input {
    text-align: center;
  }
}

p.dollarSign {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 24px;
  font-weight: 500;
  opacity: 0.5;
  margin: 0;
}

.priceToHigh {
  :global span.max-check {
    color: tomato;
  }
}

.priceToLow {
  :global span.min-check {
    color: tomato;
  }
}

.smallNftCard {
  margin: 0 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
