@use "/src/config" as *;

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
}
.loaderContainer .loader {
  display: inline-flex;
  width: 100px;
  height: 100px;
  border: 10px solid $primary-text;
  border-radius: 50%;
  border-top-color: $primary-bg;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.loaderContainer .loader {
  position: absolute;
  left: calc(50% - 50px);
  top: 50%;
}

.loaderContainer .loader:before,
.loaderContainer .loader:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background: #ffffff;
  border-radius: 50%;
}

.loaderContainer .loader:before {
  left: 0;
  transform: translate(3px, 3px);
}

.loaderContainer .loader:after {
  right: 0;
  transform: translate(-3px, 3px);
}

.loaderContainer p {
  position: relative;
  top: 50%;
  left: calc(
    50% - 2.5ch
  ); /*must be half of char of text Loading ... = 11 char*/
  padding-top: 1.2rem;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
