@use "/src/config" as *;

$svgHeight: 36px;
$svgWidth: 36px;

.detailsContainer {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 100%;
  color: $primary-text;
}
.bgBlurred {
  position: absolute;
  background: $bg-blurred-filter;
  filter: blur(500px);
  pointer-events: none;
  opacity: 0.24;
  pointer-events: none;
  height: 100%;
  width: 100%;
}

.detailsContainer .item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-top: 4.5rem;
  width: 100%;
  padding-bottom: 4.5rem;
}

.detailsContainer .item:first-child {
  background: $primary-bg;
  flex-basis: 40%;
}
.detailsContainer .item:last-child {
  background: linear-gradient(
    270deg,
    $bg-blurred-gradient-start 0%,
    $bg-blurred-gradient-end 100%
  );
  backdrop-filter: blur(64px);
  flex-basis: 60%;
  align-items: flex-start;
  padding: 0 4.5rem;
}

.infoContainer > div:first-child {
  margin: 4em 0 2em 0;
}
.infoContainer div h1:first-child {
  line-height: 0.875em;
  margin-bottom: 0;
}
.infoContainer div h1:last-child {
  margin: 0;
}

.infoContainer p {
  max-width: 50%;
}

.detailsContainer div > video {
  width: initial;
  height: 100%;
  max-height: 50vh;
  border-radius: 12px;
}

.mobileOnly {
  display: none;
}
.desktopOnly {
  display: block;
}

.card {
  margin: 1rem 0;
  display: flex;
}

.card div:not(.historyNavContainer) {
  display: flex;
  flex-direction: column;
}

.card div > * {
  margin: 0;
}

.historyContainer {
  min-width: 50%;
}
.actionContainer button {
  margin: 0.5rem 0;
  width: 100%;
}

.actionContainer h3 {
  margin: 0;
  font-weight: 700;
  font-size: 2rem;
  color: $wallet-text-primary;
}

.error {
  color: $error-text;
  font-size: 16px !important;
  text-align: center;
}

.errorContainer > * {
  color: $error-text;
  caret-color: $error-text;
  display: block;
}
.errorContainer + p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.warningContainer > * {
  /* font-size: 0.875rem; */
  color: $primary-text;
  caret-color: $primary-text;
  display: block;
}
.warningContainer > p:first-child {
  color: $error-text;
  caret-color: $error-text;
}
.warningContainer > p {
  margin: 0 0.25em !important;
}
.justify {
  text-align: justify;
}
.successMsg {
  position: fixed;
  background-color: $primary-btn-bg;
  color: $primary-btn-text;
  display: flex;
  padding: 1rem;
  bottom: 20px;
  align-items: center;
  border-radius: 10px;
  left: calc(50% - 9.5ch);
  height: 50px;
  z-index: 101;
}

.actionContainer > p:nth-of-type(2) {
  margin-bottom: 0;
  display: block;
}
.actionContainer a {
  font-size: 14px;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 0.5px solid $secondary-shades-500;
  display: inline-block;
}

.fullscreenModal {
  background: transparent !important;
  margin: auto;
}

@media (max-width: 1212px) {
  .infoContainer p {
    max-width: 100%;
  }
}
@media (max-width: 1065px) {
  .infoContainer,
  .marketMsgBlock {
    padding: 0 1em;
  }
  /* .actionContainer {
    max-width: 100%;
  } */
  .historyContainer {
    padding-bottom: 2em;
  }
  .detailsContainer {
    flex-direction: column;
    overflow-x: hidden;
  }
  .detailsContainer .item:first-child {
    padding-top: 2rem;
  }

  .detailsContainer .item:last-child {
    align-items: center;
    width: 100%;
    padding: 0;
  }
  // .card {
  //   padding-left: var(--actionContainerPadding);
  // }

  .marketMsgBlock p {
    margin-bottom: 1rem;
    text-align: left;
  }
}

.infoMessage {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0.5em !important;
}

.infoMsgContainer {
  display: flex;
}

@media (max-width: 767px) {
  .detailsContainer > .item {
    padding: 0;
  }
  .historyContainer > .actionContainer > button {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .infoContainer {
    width: calc(100vw - 48px);
  }
  .actionContainer {
    /* width: 100vw; */
    max-width: none;
    border-radius: 0;
  }
}
