@use "/src/config" as *;

@use "./styles/creators";
@use "./styles/collectibles";

.marketplacePage {
  padding: 4.5em 0;
  @include container;

  h1.sectionHeading {
    margin: 0 0 24px;
    display: inline-flex;
    align-items: center;
    font-size: 2em;

    img.merchantProfileImg {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 2rem;
    }
  }

  .search {
    margin-right: auto;
  }

  .sort {
    width: 100%;
  }

  .separator {
    margin: 2rem 0 3rem;
  }

  h3.sectionHeading {
    margin: 0 0 24px;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 1%;
    color: $primary-text;
  }

  .headerFields {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    gap: 2rem;

    :last-child {
      grid-column-start: 3;
    }
    :first-child {
      grid-column-start: 1;
    }

    button {
      width: 100%;
    }
  }

  .sectionHeading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0 24px;

    h3.sectionHeading,
    p {
      margin: 0;
    }

    .showAllBtn {
      color: $primary-text;
      opacity: 1;
      border-bottom: 1px solid rgba($primary-text, 0.4);
      cursor: pointer;
      transition: 0.3s;
      user-select: none;

      &:hover {
        border-color: $primary-text;
      }
    }
  }

  @include creators.section;
  @include collectibles.section;

  @include breakpointMax(sm) {
    h1.sectionHeading {
      font-size: 2.5em;
    }
  }

  @include breakpointMax(md) {
    .headerFields {
      display: flex;
      gap: 1rem;
    }

    h1.sectionHeading {
      font-size: 3em;
    }

    h1.sectionHeading + .headerFields {
      margin-bottom: 1rem;
    }

    .sort {
      max-width: 212px !important;
    }
  }

  @include breakpointMax(lg) {
    .headerFields {
      grid-template-columns: 2fr 1fr 2fr;
    }
  }
}

.sortMobile {
  display: flex;
}

.sortMobileList {
  top: 80px;
  right: 0;
}
