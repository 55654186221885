@use "/src/config" as *;

.table {
  border-collapse: collapse;
  width: 100%;
}
.table tr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.table tr td:first-of-type {
  width: 20px;
  padding-right: 0;
}
.table tr td:nth-of-type(3) {
  width: 100px;
}
.table:not(.skeleton) tr:hover {
  background-color: $secondary-shades-700;
  border-radius: 4px;
  cursor: pointer;
  border-top-color: $secondary-shades-100;
}
.table:not(.skeleton) tr:hover + tr {
  border-top-color: $secondary-shades-100;
}
.table:not(.skeleton) tr:hover td:first-of-type {
  border-radius: 4px 0px 0px 4px;
}
.table:not(.skeleton) tr:hover td:last-of-type {
  border-radius: 0px 4px 4px 0px;
}
.table:not(.skeleton) tr:hover button {
  cursor: pointer !important;
}
.table:not(.skeleton) tr:hover button > span {
  background-color: #ffffff;
  cursor: pointer;
}
.table td,
.table th {
  text-align: left;
  padding: 15px;
}

.table td > span {
  opacity: 0.5;
}

/*****rouf*****************/

.skeleton {
  position: relative;
  background-color: $secondary-shades-100;
  border-radius: 5px;
  overflow: hidden;
  border-radius: 0;
}
.skeleton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    30deg,
    transparent,
    $secondary-shades-500,
    transparent
  );
  -webkit-animation: skeleton 1s infinite;
  animation: skeleton 1s infinite;
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox > span {
  color: #34495e;
  padding: 0.5rem 0.25rem;
}

.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 50%;
  outline: none;
  transition-duration: 0.3s;
  background-color: #2e2e38;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  content: "\f00c";
}

.checkbox > input:checked + span::before {
  content: "\2713";
  display: block;
  text-align: center;
  color: #2e2e38;
  position: absolute;
  left: 0.3rem;
  top: 0.1rem;
}

.checkbox > input:active {
  border: 2px solid #34495e;
}

@-webkit-keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0px 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0px 50%;
  }
}
