@use "/src/config" as *;

.shebaFooter {
  background-color: #000;
  display: flex;
  padding: 2em 0 3em;

  .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .brandingSection {
    text-align: center;
    margin-bottom: 50px;
    .brandingImg {
      max-width: 100%;
    }
  }

  .linkSection {
    position: relative;
    align-items: center;

    ul {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      li {
        display: inline;
        margin: 15px 0 0 0;
        padding: 0 5px;

        a {
          font-size: 11px;
          text-decoration: none;
          color: #a0a0a0;
          padding: 5px 10px;

          &:hover {
            color: #cf9c51;
          }
        }

        img {
          width: 32px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  .copyrightSection {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;

    p {
      font-size: 12px;
      color: #fff;
    }
  }
}
