@forward "/src/config/styles" with (
  $primary-text: white !default,
  $accent: white !default,
  $primary-bg: #181818 !default,
  $nav-bg: #06060a !default,
  $secondary-text: #a2a2b0 !default,
  $primary-50: #7f7f7f !default,
  $secondary-shades-100: #a2a2b0 !default,
  $secondary-shades-500: #2e2e38 !default,
  $secondary-shades-700: #21212c !default,
  $secondary-shades-900: #161620 !default,
  $bg-blurred-gradient-start: #06060a !default,
  $bg-blurred-gradient-end: #06060a !default,
  $login-img-size: cover !default,
  $login-img-position: unset !default,
  $scrollbar: #4c4c4c !default,

  $split-section-bg-1: rgb(36, 31, 32) !default,
  $split-section-bg-2: #06060a !default,
  $split-section-filter-bg: none !default
);
