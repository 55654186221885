@use '../variables' as *;

@forward "/src/config/styles/components/button" with (
    $primary-btn-text: black !default,
    $primary-chip-bg: white !default,
    $primary-chip-text: #06060a !default,
    $secondary-btn-hover-text: #06060a !default,
    $slider-circle: hsla(0,0%,100%,.4) !default,
    $slider-bg: hsla(0,0%,100%,.4) !default,
);
