@use "/src/config" as *;

ul.radioGroup {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  display: flex;
}

ul.radioGroup li {
  color: #aaaaaa;
  position: relative;
  display: flex;
  padding: 0 1em;
  box-sizing: content-box;
}

ul.radioGroup li:first-child {
  padding-left: 0;
}

ul.radioGroup li:hover label {
  color: $secondary-shades-100;
  cursor: pointer;
}

ul.radioGroup li input[type="radio"] {
  display: none;
}
ul.radioGroup li input[type="radio"] + label span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: transparent;
  vertical-align: middle;
  border: 2px solid $accent;
  border-radius: 50%;
  padding: 2px;
  margin: 0 5px;
}
ul.radioGroup li input[type="radio"]:checked + label span {
  width: 10px;
  height: 10px;
  background: $accent;
  background-clip: content-box;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}
