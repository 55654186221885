@use "/src/config" as *;

.dFlex {
  display: flex;
  flex-direction: row !important;
  justify-items: end;
  align-items: center;
}
.dFlex span {
  font-size: 12px;
  white-space: nowrap;
}

.statusCircleOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  min-width: 10px;
  min-height: 10px;
  padding: 2px;
  border-radius: 50%;
}
.statusCircleInner {
  justify-content: center;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
}
.blackOuter {
  background-color: rgba(0, 0, 0, 0.4);
}
.blackInner {
  background: rgba(30, 29, 28, 0.8);
}

.blackText {
  color: rgba(30, 29, 28, 0.8);
}

.redOuter {
  background-color: rgba(199, 45, 45, 0.4);
}
.redInner {
  background-color: #f21f1f;
}

.redText {
  color: #f21f1f;
}
.greenOuter {
  background-color: rgba(59, 152, 113, 0.4);
}

.greenInner {
  background-color: #00874e;
}
.greenText {
  color: #00874e;
}
.blueOuter {
  background-color: rgba(60, 102, 196, 0.4);
}

.blueInner {
  background-color: #1845ab;
}
.blueText {
  color: #1845ab;
}

.orangeOuter {
  background-color: rgba(189, 117, 48, 0.4);
}

.orangeInner {
  background-color: rgb(189, 117, 48);
}

.orangeText {
  color: rgb(189, 117, 48);
}

.turquoiseOuter {
  background-color: rgba(32, 80, 72, 0.4);
}
.turquoiseInner {
  background-color: rgb(32, 80, 72);
}

.turquoiseText {
  color: rgb(32, 80, 72);
}

.yellowOuter {
  background-color: rgba(213, 160, 33, 0.4);
}

.yellowInner {
  background-color: rgb(213, 160, 33);
}

.yellowText {
  color: rgb(213, 160, 33);
}

.greyOuter {
  background-color: $secondary-shades-100;
}

.greyInner {
  background-color: $secondary-shades-100;
}

.greyText {
  color: $secondary-shades-100;
}
