@use "../../../../config/index" as *;

.container {
  display: grid;
  grid-column: span 1;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: minmax(44px, max-content);
  width: 100%;
  margin-inline: auto;
}
.collectionContainer {
  padding-top: 2rem;
  grid-column-start: 2;
  grid-row-start: 2;
}

.collectionContainer,
.filter {
  padding-left: 4rem;
}

.filterChip {
  background: $secondary-shades-700;
  border-radius: 50%;
  gap: 0.5rem;
  border: 0;
  display: flex;
  justify-content: space-between;
  margin-right: 1em;
}
.gridStart1 {
  grid-column-start: 1;
}
.gridStart2 {
  grid-column-start: 2;
}
.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  grid-column-start: 2;
  grid-row-start: 1;
}
.custom {
  border-color: unset;
}
.cell {
  box-sizing: border-box;
  font-size: 16px;
  color: $primary-text;
  cursor: default;
  height: 35px;
  display: inline-flex;
  align-items: center;
  border: solid 1px $primary-50;
  padding: 0 0 0 10px;
  margin-top: 7px;
  margin-right: 10px;
  border-radius: 4px;
}
.cell > * {
  margin: 0.5rem;
}

.desktopOnly {
  display: block;
  grid-column-start: 1;
  grid-row-start: 2;
}
.mobileOnly {
  display: none;
}
.mobileFilterModal {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.mobileFilterTable {
  width: 100%;
}
.mobileFilterContentClass {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.clearAllLbl {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
@include breakpointMax(lg) {
  .container {
    grid-template-columns: 2fr 5fr;
  }
}
@include breakpointMax(md) {
  .container {
    grid-template-columns: 1fr;
  }
  .collectionContainer,
  .filter {
    padding-left: 0;
  }
  .filter {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    grid-column-start: 1;
    grid-row-start: 2;
    gap: 1rem;
    margin-top: 1em;
  }
  .collectionContainer {
    grid-column-start: 1;
    grid-row-start: 3;
    padding-top: 0rem;
  }
  .mobileOnly {
    display: block;
  }
  .desktopOnly {
    display: none;
  }
  .clearAllLbl {
    grid-column-start: 3;
  }
}
