@use "/src/config" as *;

.figure {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.figure > * {
  cursor: pointer;
}

.figure > div {
  padding: 2em;
}
.figure h3 {
  margin-top: 8px;
}
.figure h3,
.figure h4 {
  margin-bottom: 0;
  max-width: 300px;
  font-size: 16px;
}
.figure h4 {
  color: $secondary-text;
  margin: 0;
}

.figure h3,
.figure svg {
  display: inline-block;
}
.figure svg {
  position: relative;
  top: 5px;
  left: 10px;
}

.figure .imageContainer {
  position: relative;
  width: 100%;
  padding-bottom: 136.842%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.figure .imageContainer .unclaimedButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  background: #fff;
  padding: 3px 12px;
  border-radius: 999px;
  font-size: 12px;
  font-weight: 500;
  width: max-content;
}

.figure .bullet {
  position: relative;
  display: inline-block;
  width: 12px;
  margin-right: 6px;
  height: 100%;
}

.figure .bullet:before {
  content: "";
  position: absolute;
  transform: translateY(-100%);
  width: 12px;
  height: 12px;
  background: $secondary-shades-500;
  border-radius: 50%;
}

.figure .bullet.active:before {
  background-color: $active-state;
}

.nftName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
