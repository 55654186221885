@use "/src/config" as *;

.tabsContainer {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: 0.875rem; */
}

.tabsContainer > span {
  margin-right: 0.875rem;
  margin-bottom: 0.875rem;
}
@media (max-width: 767px) {
  .tabsContainer {
    margin-right: 0;
    /* margin-left: 0.875rem */
  }
}