@use "/src/config" as *;

.holder {
  position: relative;
}

.holder input:read-only {
  cursor: pointer;
}

.holder input:disabled {
  cursor: not-allowed;
}

.dataHolder {
  margin-top: -1rem;
  border-radius: 4px !important;
  background-color: transparent;
  border: 0.5px solid $field-border-color;
}

.holder .dataHolder,
.holder .noData {
  box-shadow: $secondary-shades-500;
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  min-width: 200px;
  padding: 0px;
  border-radius: 4px;
  pointer-events: none;
  display: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.holder .dataHolder {
  width: revert;
  min-width: 100%;
}

.holder .keepTyping,
.holder .noData {
  text-align: center;
  font-size: 14px;
}

.holder.focused .keepTyping,
.holder.focused .dataHolder {
  position: absolute;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transform: translateY(0px);
}

.holder.focused.left .keepTyping,
.holder.focused.left .dataHolder,
.holder.focused.left {
  right: 8px;
}
.holder .dataHolder .option {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0 0.8em;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 600;
  background-color: $dropdown-menu-bg-color;
}

.focused .noData {
  min-height: 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: absolute;
  margin: 0;
  opacity: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid $field-border-color;
  background-color: $dropdown-menu-bg-color;
}

.holder .dataHolder .option span {
  display: flex;
  align-items: center;
}

.holder .dataHolder .option.active {
  background-color: $dropdown-menu-active-bg-color;
}

.dataHolder.iconOnly {
  top: 38px;
}

.icon {
  border-radius: 50%;
  border: solid 1px $primary-text;
  padding: 0.25rem;
}
.icon:hover {
  cursor: pointer;
  pointer-events: all;
}

/* Remove focus outline */
.focused {
  outline: none;
  /* background-color: #ffffff; */
}

/* Remove IE arrow */
option::-ms-expand {
  display: none;
}

@media (max-width: 450px) {
  .holder.focused.left .keepTyping,
  .holder.focused.left .dataHolder,
  .holder.focused.left .noData {
    right: 8px;
  }
}
