/* Start of margin and padding utility classes */
.ma-0 {
  margin: 0 !important;
}

.ma-1 {
  margin: 0.25rem !important;
}

.ma-2 {
  margin: 0.5rem !important;
}

.ma-3 {
  margin: 1rem !important;
}

.ma-4 {
  margin: 1.5rem !important;
}

.ma-5 {
  margin: 3rem !important;
}

.ma-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.pa-0 {
  padding: 0 !important;
}

.pa-1 {
  padding: 0.25rem !important;
}

.pa-2 {
  padding: 0.5rem !important;
}

.pa-3 {
  padding: 1rem !important;
}

.pa-4 {
  padding: 1.5rem !important;
}

.pa-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pl-n-4 {
  padding-left: -1.5rem !important;
}

/* End of margin and padding utility class*/
/* Start of flex utility class */
.d-flex {
  display: flex;
}
.d-none {
  display: none;
}
.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.container {
  width: 100%;
  padding: 1rem 2rem;
}

@media (min-width: 768px) {
  .container {
    padding: 3rem 6.6rem;
  }
}

/* End of flex utility class*/
/* Start of text utility class */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
/* End of text utility class*/
