@use "/src/config" as *;

.smallNftCard {
  margin: 0 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

p.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: tomato;
  }

  svg {
    color: tomato;
    margin-right: 0.5em;
  }
}
