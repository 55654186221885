@use "/src/config" as *;

.footer {
  background-color: $nav-bg;
  color: $primary-text;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 0 2em;
  font-weight: 400;
}

.footer nav {
  display: flex;
  z-index: 3;
  justify-content: space-between;
  align-items: center;
}
.footer nav a {
  opacity: 1;
}

.footer div p:last-child {
  opacity: 0.5;
}
.footer div a {
  text-decoration: none;
  color: inherit;
}

.socialMediaContainer {
  display: flex;
  margin: 1.5em 0;

  nav > a {
    font-size: 1.5em;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid $accent;
    }
  }
}
.socialMediaContainer > nav a:first-child {
  color: inherit;
  margin-left: 0;
}
@media (max-width: 837px) {
  .footer {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 0;
    padding: 2rem 1.5rem 1.5rem;
  }
  .footer nav {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer nav:first-of-type > a {
    margin: 0.2rem 0.5rem;
  }
  .footer nav:not(:last-child) {
    margin-bottom: 1em;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer nav a {
    margin: 0.5em 0;
  }
}
