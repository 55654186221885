@use "/src/config" as *;

.activeSwitch {
  position: absolute;
  top: 1em;
  right: 1.5em;
  display: flex;
  align-items: center;
}

.activeSwitch p.label {
  color: $secondary-text;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  margin: 0 6px 0 0;
  opacity: 1;
}

.activeSwitch .slider {
  position: relative;
  width: 28px;
  height: 16px;
  background-color: $slider-bg;
  border-radius: 999px;
  cursor: pointer;
}

.activeSwitch .slider:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: $slider-circle;
  border-radius: 50%;
}

.activeSwitch .slider[data-active~="true"]:before {
  right: unset;
  left: 50%;
  background: $active-state;
}
