@use "/src/config" as *;

.holder {
  display: flex;
  border-radius: 50%;
  border: solid 2px $secondary-shades-700;
  background-color: $secondary-shades-100;
  position: relative;
  height: 140px;
  max-width: 140px;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: $secondary-shades-700;
  transition: all 0.3s;

  margin: 2rem auto;
}

.holder:hover,
.holder.dragging {
  border: solid 2px $primary-text;
}

.holder.disabled {
  pointer-events: none;
}

.holder input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 1;
}

.holder svg {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.hoverContent {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  user-select: none;
}

.hoverContent span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 0 4px;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}

.holder:hover .hoverContent {
  display: flex;
}

.uploadModalContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.uploadModalContainer h3 {
  color: $primary-text;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 0.5rem;
}

.uploadModalContainer .upload {
  margin: 1.5rem auto;
}

.uploadModalContainer button {
  margin: 0 0 0.5rem;
}

.uploadModalContainer .deleteCurrentPhoto {
  color: $primary-text;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 0 4px;
  margin: 0.5rem 0 0;
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
}

.uploadModalContainer .errorContainer {
  margin: 0;
  padding: 0;
  display: inline-flex;
}

.uploadModalContainer .errorContainer span {
  margin: 0 0 1rem;
  color: red;
}
