@use "/src/config" as *;

.imgContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  z-index: 1;
}

.imgBorder:before {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  background: transparent;
  border-radius: 50%;
  z-index: -1;
  border: 1px solid $secondary-shades-100;
}

.holder {
  position: relative;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  box-sizing: border-box;
  color: $primary-btn-text;
  font-weight: bold;
  background-color: #91919b;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.holder.cover {
  background-size: cover;
}

.holder span {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: solid 1px #ffffff;
  position: absolute;
  right: -10px;
  bottom: 1px;
}

.uploadOnClick {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  user-select: none;
}

.uploadOnClick span {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 0 4px;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}

.imgContainer:hover .uploadOnClick {
  display: flex;
}
