@use "/src/config" as *;

$borderRadius: 12px;
$iconContainerSize: 50px;

@include container;

.container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.headingImage {
  position: relative;
  width: 100%;
  height: 400px;
  padding-bottom: 0;
  margin-bottom: 2em;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $borderRadius;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: $borderRadius;
      background: linear-gradient(
        180deg,
        rgba(20, 20, 31, 0) 0%,
        rgba(14, 14, 18, 0.6) 100%
      );
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 2em;
    color: white;

    h1 {
      font-size: 2.5rem;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;

  .gridItem {
    .card {
      background: $secondary-shades-500;
      border-radius: $borderRadius;
      padding: 1.5em;
      margin-bottom: 1.5em;

      h2 {
        margin: 0 0 1rem;
        font-size: 1rem;
      }

      hr {
        margin: 0 0 1rem;
      }

      p {
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.75rem;
        color: $secondary-shades-100;
      }

      button {
        width: 100%;
      }

      .tempAudioPlayer {
        width: 100%;
        margin-bottom: 1em;
      }

      .playlistTracks {
        .track {
          border-bottom: 1px solid rgba($primary-text, 0.1);
          cursor: pointer;

          .trackContent {
            display: flex;
            align-items: center;
            gap: 0.75em;
            padding-top: 5px;

            .sectionImage {
              img.trackImage {
                height: 55px;
                width: 55px;
                object-fit: cover;
              }
            }

            .sectionInfo {
              margin-bottom: 0.5em;

              h6 {
                margin: 0 0 0.25em;
                font-size: 1em;
                font-weight: 700;
                color: $primary-text;
                line-height: 1;
              }

              p {
                margin: 0;
                font-size: 0.75em;
                font-weight: 400;
                color: $secondary-shades-100;
                line-height: 1;
              }
            }

            .sectionActions {
              margin-left: auto;
              padding-right: 0.5em;
            }
          }

          &:hover {
            .trackContent {
              background-color: rgba($primary-text, 0.1);
            }
          }

          &:last-child {
            border-bottom: unset;
            margin-bottom: 0;
          }
        }
      }
    }

    .embedSpotifyPlaylist {
      display: block;
      background: $secondary-shades-500;
      width: 100%;
      height: 20em;
      padding: 1em;

      iframe.spotifyPlaylist {
        width: 100%;
        height: 100%;
        border: unset;
      }
    }

    .requirements {
      hr {
        margin: 2rem 0 2rem;
      }

      p {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
}

@include breakpoint(sm) {
  .headingImage {
    height: 0;
    padding-bottom: 75%;
  }
}

@include breakpoint(md) {
  .headingImage {
    padding-bottom: 66.6%;
  }
}

@include breakpoint(lg) {
  .headingImage {
    padding-bottom: 50%;
  }

  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(xl) {
  .container {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  .headingImage {
    padding-bottom: 45%;
    margin-bottom: 5em;

    .content {
      padding: 2em 2em 5em;
    }
  }

  .grid {
    gap: 5em;
  }
}

.imgScrim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(black, 0.4);
}

.imgLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: #06060a;
  padding: 5px 12px;
  border-radius: 999px;
  font-size: 1.5em;
}
