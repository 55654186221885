@use "../variables" as *;

$primary-btn-bg: $accent !default;
$primary-btn-text: #ffffff !default;
$primary-btn-hover-bg: $accent !default;

$secondary-btn-bg: transparent !default;
$secondary-btn-text: $primary-text !default;

$secondary-btn-hover-bg: $accent !default;
$secondary-btn-hover-text: #ffffff !default;

$primary-chip-bg: #000 !default;
$primary-chip-text: #ffffff !default;

$slider-circle: $secondary-shades-700 !default;
$slider-bg: rgba($secondary-text, 0.2) !default;
